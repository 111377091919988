import MetaHead from "./MetaHead";
import Header from "./Header";

import { useEffect, useRef, useState } from "react";
import { ApiNetworkProvider } from "../node_modules/@elrondnetwork/erdjs-network-providers/out/apiNetworkProvider";
import { getCookie } from "../utils/handleCookies";
import Footer from "./Footer";

const MainLayout = ({ children, firebaseUser, background = true }) => {
  
  return (
    <>
      <div className="sticky top-0 z-50"> {/* made a sticky header per https://stackoverflow.com/a/66803799/2036135 */}
        <Header firebaseUser={firebaseUser} background={background}></Header>
      </div>
      <div className="relative">
      {children}
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
