import Head from "../node_modules/next/head";

const defaultMetaTags = null;

const MetaHead = ({
  title,
  description,
  author,
  og_title,
  og_description,
  og_image,
  twitter_title,
  twitter_description,
  twitter_image,
}) => {
  return (
    <Head>
      <title> {title ?? defaultMetaTags.title} </title>
      <meta name="description" content={description ?? defaultMetaTags.description} />
      <meta name="author" content={author ?? defaultMetaTags.author}></meta>
      <meta property="og:title" content={og_title ?? defaultMetaTags.og_title} />
      <meta property="og:description" content={og_description ?? defaultMetaTags.og_description} />
      <meta property="og:image" content={og_image ?? defaultMetaTags.og_image} />
      <meta name="twitter:title" content={twitter_title ?? defaultMetaTags.twitter_title} />
      <meta name="twitter:description" content={twitter_description ?? defaultMetaTags.twitter_description} />
      <meta name="twitter:image" content={twitter_image ?? defaultMetaTags.twitter_image} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    </Head>
  );
};

export default MetaHead;
