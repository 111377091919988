import React, { useEffect, useState } from 'react'
import router from 'next/router';
import $ from "jquery";
import Link from '../node_modules/next/link';
import { toast, ToastContainer } from '../node_modules/react-toastify/dist/react-toastify'
import MetaHead from './MetaHead';
import Image from '../node_modules/next/image';

import { GoogleAuthProvider, EmailAuthProvider, signOut } from "firebase/auth";
import StyledFirebaseAuth from '../components/StyledFirebaseAuth'
import { firebaseAuthGet } from '../components/Firebase';

declare global {
  let pgia: any;
}

const Header = ({ firebaseUser, littlePlanetOwner = true, background = true }) => {

    // initialise Firebase auth
    const auth = firebaseAuthGet();


    const saveUserDetails = (authResult, redirectUrl) => {
        console.log('Just logged in - authResult:', authResult);
        $(".close_icon").trigger('click'); // ugly, but work with what we already have
        return true; // signInSuccessWithAuthResult requires a boolean return value, and true seems to mean redirect using signInSuccessUrl
    };


    // Configure Firebase UI config
    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/dashboard',
        tosUrl: '/terms-of-service',
        privacyPolicyUrl: '/privacy-policy',
        signInOptions: [
            GoogleAuthProvider.PROVIDER_ID,
            EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: saveUserDetails
        }
    };


    const openAuthModal = () => {
        // close all modal
        $(".modal").hide();
        // open modal now
        $(".auth-modal").fadeIn();
    };


    // toast
    const showToast = (type, message) => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    };
    

    useEffect(() => {
        // close modal
        $(".close_icon").click(function () {
            $(this)
                .parent()
                .parent()
                .fadeOut();
        });

        const refreshPinegrow = () => {
          // idea for this came from https://forum.pinegrow.com/t/pinegrow-interactions-in-nextjs/6187
          let el = document.body;
          pgia.elementAnimationsManager.refreshAnimations(el, true);
        }

        router.events.on("routeChangeComplete", refreshPinegrow);

        return () => {
          router.events.off('routeChangeComplete', refreshPinegrow)
        }
    });
    

    return (
      <div>
        <ToastContainer />
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
            integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
        />

        {/* <!-- auth popup start --> */}
        <div className="modal modal_hidden auth-modal">
          <div className="modal-page">
            <div className="close_icon cursor-pointer">
                <i className="fa-regular fa-circle-xmark"></i>
            </div>

            <div className="form">
              <form className="login-form">
                <div className="heading">
                    <h1>Login</h1>
                </div>
                {/* START: Firebase auth */}
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                {/* END: Firebase auth */}

              </form>
            </div>
          </div>
        </div>
        {/* <!-- auth popup end --> */}

        <MetaHead
                title="Rescua"
                description="Rescua.earth provides a better way to finance renewable technology and NetZero projects."
                author="DL/JN"
                og_title="Rescua"
                og_description="Rescua"
                og_image=""
                twitter_title="Rescua"
                twitter_description="Rescua"
                twitter_image=""/>

        <div
            className={(!background ? 'absolute' : '') + ' w-full'}
            style={{ background: background ? '#54a3d8' : 'transparent' }}
        >
          <header className="bg-opacity-90 bg-rescuablue-500 border-b-0 fixed inset-x-0 top-0 w-full z-50">

            <nav className="container flex flex-wrap items-center mx-auto px-4 py-2 lg:py-0">

              <Link id="logo" href="/" passHref>
                <a  className="mr-auto text-white text-xl">
                  <img width={200} src="/assets/images/rescua-logo.png" />
                </a>
              </Link>
              <button
                  id="hamburger-menu"
                  className="bg-white hover:bg-gray-300 hover:text-white px-3 py-2 rounded text-gray-300 lg:hidden"
                  data-name="nav-toggler"
                  data-pg-ia="{&quot;l&quot;:[{&quot;name&quot;:&quot;NavMenuToggler&quot;,&quot;trg&quot;:&quot;click&quot;,&quot;a&quot;:{&quot;l&quot;:[{&quot;t&quot;:&quot;^nav|[data-name=nav-menu]&quot;,&quot;l&quot;:[{&quot;t&quot;:&quot;set&quot;,&quot;p&quot;:0,&quot;d&quot;:0,&quot;l&quot;:{&quot;class.remove&quot;:&quot;hidden&quot;}}]},{&quot;t&quot;:&quot;#gt# span:nth-of-type(1)&quot;,&quot;l&quot;:[{&quot;t&quot;:&quot;tween&quot;,&quot;p&quot;:0,&quot;d&quot;:0.2,&quot;l&quot;:{&quot;rotationZ&quot;:45,&quot;yPercent&quot;:300}}]},{&quot;t&quot;:&quot;#gt# span:nth-of-type(2)&quot;,&quot;l&quot;:[{&quot;t&quot;:&quot;tween&quot;,&quot;p&quot;:0,&quot;d&quot;:0.2,&quot;l&quot;:{&quot;autoAlpha&quot;:0}}]},{&quot;t&quot;:&quot;#gt# span:nth-of-type(3)&quot;,&quot;l&quot;:[{&quot;t&quot;:&quot;tween&quot;,&quot;p&quot;:0,&quot;d&quot;:0.2,&quot;l&quot;:{&quot;rotationZ&quot;:-45,&quot;yPercent&quot;:-300}}]}]},&quot;pdef&quot;:&quot;true&quot;,&quot;trev&quot;:&quot;true&quot;}]}">
                  <span className="block border-b-2 border-current my-1 w-6" />
                  <span className="block border-b-2 border-current my-1 w-6" />
                  <span className="block border-b-2 border-current my-1 w-6" />
              </button>

              
              <div id="nav-menu" data-name="nav-menu"
                        className="hidden lg:flex lg:items-center lg:w-auto w-full">
                <div className="flex flex-col object-right lg:flex-row">
                  <Link href="/#mint-section" passHref>
                    <a className="hover:text-gray-300 px-0 py-2 text-amber-400 lg:px-4 lg:py-6">
                      START MINTING
                    </a>
                  </Link>
                  <Link href="/" passHref>
                    <a className="hover:text-gray-300 px-0 py-2 text-white lg:px-4 lg:py-6">
                      Home
                    </a>
                  </Link>
                  <Link href="/faq" passHref>
                    <a className="hover:text-gray-300 px-0 py-2 text-white lg:px-4 lg:py-6">
                      FAQ
                    </a>
                  </Link>
                  {/* <a href="/contact" className="hover:text-gray-300 px-0 py-2 text-white lg:px-4 lg:py-6">Contact</a> */}
                </div>
                {firebaseUser ? (
                  <div className="flex flex-col ml-auto lg:flex-row">
                    <Link href="/dashboard" passHref>
                      <a className="hover:text-gray-300 px-0 py-2 text-white lg:px-4 lg:py-6">
                        My Account
                      </a>
                    </Link>
                  </div>
                ) : (
                  <div onClick={openAuthModal}
                            className="flex flex-col ml-auto lg:flex-row">
                    <Link href="#" passHref>
                      <a className="hover:text-gray-300 px-0 py-0 text-white lg:px-4 lg:py-6">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em" className="inline-block mr-2">
                            <g>
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.987-3.744A7.966 7.966 0 0 0 12 20c1.97 0 3.773-.712 5.167-1.892A6.979 6.979 0 0 0 12.16 16a6.981 6.981 0 0 0-5.147 2.256zM5.616 16.82A8.975 8.975 0 0 1 12.16 14a8.972 8.972 0 0 1 6.362 2.634 8 8 0 1 0-12.906.187zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                            </g>
                        </svg>
                        Login/Sign Up
                      </a>
                    </Link>
                  </div>
                )}

              </div>
            </nav>
          </header>
        </div>
      </div>
    )
}

export default Header